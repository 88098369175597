import React from 'react'
import Img from "gatsby-image"
import classNames from "classnames"
import "./FlagTeaser.scss"

const FlagTeaser = props => {
  const { image, slitScanHorizontal, classes } = props
  const imgFluid = image.childImageSharp.fluid

  const segements = (()=>{
    const result = [];
    for(let i = 1; i <= 3; i += 1) {
      const segment = (
        <div
          key={`${imgFluid}-${i}`}
          className={`image-segment image-segment-${i}`}
        >
          <Img fluid={imgFluid} backgroundColor="black" fadeIn />
          <Img fluid={imgFluid} backgroundColor="black" fadeIn />
        </div>
      )
      result.push(segment)
    }
    return result
  })()

  return (
    <div className={(classNames("flag-teaser", classes))}>
      <div className={classNames(slitScanHorizontal && "slit-scan-horizontal", "segments")}>{segements.map(segment => segment)}</div>
    </div>
  )
}

export default FlagTeaser
