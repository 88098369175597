import React from "react"
import PropTypes from "prop-types"
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"
import FlagTeaser from "../../Containers/FlagTeaser/FlagTeaser"

import "./RelatedCountries.scss"

const RelatedCountries = (props) => {
  const { countries, pageContext } = props

  const currentCountryIndex = countries.findIndex((country) => country.node.id === pageContext.id)
  const filteredCountries = []

  if(currentCountryIndex !== 0 && currentCountryIndex !== countries.length - 1) {
    filteredCountries.push(countries[currentCountryIndex-1], countries[currentCountryIndex+1])
  } else if(currentCountryIndex === 0) {
    // first country
    filteredCountries.push(countries[countries.length - 1], countries[currentCountryIndex+1])
  } else if(currentCountryIndex === countries.length - 1) {
    // last country
    filteredCountries.push(countries[currentCountryIndex-1], countries[0])
  }

  return (
    <div className="related-countries container py-2 py-md-5 border-top">
      <div className="row">
        <div className="col-md">
          <h2 className="mt-2">Explore other Flags</h2>
        </div>
        <div className="col-md">
          <div className="row px-0">
            {
              filteredCountries.length > 0 && filteredCountries.map((country) => (
                <span className="col-6 mt-2 ml-auto pb-2">
                  <PageTransitionLink
                    to={`/${country.node.slug}`}
                    key={country.node.id}
                  >
                    <FlagTeaser 
                      image={country.node.flagPng}
                      slitScanHorizontal={country.node.slitScanHorizontal}
                    />
                    <span className="text-s">{country.node.title}</span>
                  </PageTransitionLink>
                </span>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

RelatedCountries.propTypes = {
  countries: PropTypes.instanceOf(Array)
}

RelatedCountries.defaultProps = {
  countries: []
}

export default RelatedCountries
