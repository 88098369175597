import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

import PropTypes from "prop-types"

const AnimatedBarChart = props => {
  const { value, classes } = props

  const [ref, inView] = useInView()
  const animatedStyle = useSpring({ width: inView ? `${value}%`: '0%' })

  return (
    <animated.div
      ref={ref} 
      className={classes}
      style={animatedStyle}
    />
  )
}

AnimatedBarChart.propTypes = {
  value: PropTypes.number,
  classes: PropTypes.string
}

export default AnimatedBarChart
