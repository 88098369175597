import React from "react"
import { graphql } from 'gatsby'
import Layout from "Containers/Layout/Layout"
import Dashboard from "Sections/Dashboard/Dashboard.jsx"
import AdditionalDataOverview from "Sections/AdditionalDataOverview/AdditionalDataOverview.jsx"
import RelatedCountries from "Sections/RelatedCountries/RelatedCountries.jsx"
import SEO from "Src/components/seo";

const Country = props => {
  const { pageContext, data } = props

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Dashboard country={data.country.edges[0] && data.country.edges[0].node || data.altCountry.edges[0].node} />
      <AdditionalDataOverview country={data.country.edges[0] && data.country.edges[0].node || data.altCountry.edges[0].node} />
      {data.country.edges[0] && (<RelatedCountries countries={data.relatedCountries.edges} pageContext={pageContext} />) }
    </Layout>
  )
}


export default Country

export const query = graphql`
  query countryPageQuery($id: String!) {
    relatedCountries: allCountriesJson {
      edges {
        node {
          id
          slug
          title
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
        }
      }
    }
    country: allCountriesJson(
      filter: {id: {eq: $id}}
    ) {
      edges {
        node {
          id
          slug
          title
          flag
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
          dataset {
            primary {
              area {
                start
                mid
                end
              }
              temperature {
                start
                mid
                end
              }
            }
            secondary {
              retreat
              population
              emissions
              landmass
            }
            diagram {
              hasUNESCOGlaciers
              data {
                end
                label
                lat
                long
                start
              }
            }
            copy
          }
        }
      }
    }
    altCountry: allCountriesAlternativesJson(
      filter: {id: {eq: $id}}
    ) {
      edges {
        node {
          id
          slug
          title
          flag
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
          dataset {
            primary {
              area {
                start
                mid
                end
              }
              temperature {
                start
                mid
                end
              }
            }
            secondary {
              retreat
              population
              emissions
              landmass
            }
            diagram {
              hasUNESCOGlaciers
              data {
                end
                label
                lat
                long
                start
              }
            }
            copy
          }
        }
      }
    }
  }
`
