import React from "react"
import Barchart from "../../UI/Barchart/Barchart"
import AnimatedNumber from "../../UI/AnimatedNumber/AnimatedNumber"
import "./AdditionalDataOverview.scss"

const Dashboard = (props) => {
  const { country } = props

  const countryData = country.dataset
  const { secondary, diagram, copy } = countryData

  return (
    <div className="data-overview container py-2 py-lg-3">
      <div className="row">
        <div className="col-lg">
          <h2 className="mt-2">Additional Data</h2>
        </div>
        <div className="col-lg">
          <div className="row mt-2">
            <p className="text-s">{copy}</p>
          </div>
          <div className="row px-0">
            <div className="add-data-wrap col-12 d-flex align-items-end mt-2 pb-2 border-bottom d-flex">
              <AnimatedNumber number={+secondary.retreat} classes="text-number" />
              <span className="text-s">
                %
                <br />
                <span>Glacier retreat&nbsp;</span>
                <span>1995–2050</span>
              </span>
            </div>
            <div className="add-data-wrap col-12 d-flex align-items-end mt-2 pb-2 border-bottom d-flex">
              <AnimatedNumber number={+secondary.population} classes="text-number" />
              <span className="text-s">
                M
                <br />
                Population
              </span>
            </div>
            <div className="add-data-wrap col-12 d-flex align-items-end mt-2 pb-2 border-bottom d-flex">
              <AnimatedNumber number={+secondary.emissions} classes="text-number" />
              <span className="text-s">
                mt CO
                <sup>2</sup>
                <br />
                emissions per capita
              </span>
            </div>
            <div className="add-data-wrap col-12 d-flex align-items-end mt-2 pb-2 border-bottom d-flex">
              <AnimatedNumber number={+secondary.landmass} classes="text-number" />
              <span className="text-s">
                M km
                <sup>2</sup>
                <br />
                Land mass
              </span>
            </div>
          </div>
          {
            diagram.hasUNESCOGlaciers === true && (
              <div className="row mt-3">
                <Barchart diagram={diagram} headline="Retreat until 2050 by glacier" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Dashboard
