import React, { useState, useLayoutEffect } from "react"
import PropTypes from "prop-types"
// import RangeTouch from 'rangetouch'
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"
import ShaderFlag from "Sections/ShaderFlag/ShaderFlag.jsx"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import AnimatedNumber from "../../UI/AnimatedNumber/AnimatedNumber"

import "./Dashboard.scss"

const Dashboard = (props) => {
  const { country, iFrame } = props

  const countryData = country.dataset
  const { primary, diagram } = countryData

  const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2

  const minYear = 1995
  const midYear = 2020
  const maxYear = 2050
  const rangeStepsStartToEnd = maxYear - minYear
  const areaValueSectionStartToMid = (parseFloat(primary.area.mid) - parseFloat(primary.area.start))
  const areaValueSectionMidToEnd = (parseFloat(primary.area.end) - parseFloat(primary.area.mid))
  const tempValueSectionStartToMid = (parseFloat(primary.temperature.mid) - parseFloat(primary.temperature.start))
  const tempValueSectionMidToEnd = (parseFloat(primary.temperature.end) - parseFloat(primary.temperature.mid))
  const rangeStepsStartToMid = midYear - minYear;
  const rangeStepsMidToEnd = maxYear - midYear;

  const currentYear = new Date().getFullYear()
  const [year, setYear] = useState(currentYear < maxYear ? currentYear : midYear)

  const [rangePosition, setRangePosition] = useState(`${ map((year - minYear), 0, rangeStepsStartToEnd, 0, 1) }`)
  const [area, setArea] = useState(0)
  const [temperature, setTemperature] = useState(0)

  const setAreaTemp = (selectedYear) => {
    let areaVal
    let tempVal

    if(selectedYear < midYear){
      areaVal = ((areaValueSectionStartToMid / rangeStepsStartToMid) * (selectedYear - minYear)) + parseFloat(primary.area.start)
      tempVal = ((tempValueSectionStartToMid / rangeStepsStartToMid) * (selectedYear - minYear)) + parseFloat(primary.temperature.start)
    } else {
      areaVal = ((areaValueSectionMidToEnd / rangeStepsMidToEnd) * (selectedYear - midYear)) + parseFloat(primary.area.mid)
      tempVal = ((tempValueSectionMidToEnd / rangeStepsMidToEnd) * (selectedYear - midYear)) + parseFloat(primary.temperature.mid)
    }
    
    setArea(areaVal.toFixed(1))
    setTemperature(tempVal.toFixed(1))
  }

  const setRangePosFn = (selectedYear) => {
    const rangeVal = map((selectedYear - minYear), 0, rangeStepsStartToEnd, 0, 1)
    setRangePosition(`${rangeVal}`)
  }

  useLayoutEffect(() => {
    // init area, temperature
    setAreaTemp(year)
    setRangePosFn(year)
  })


  const updateYear = (e) => {
    e.preventDefault();
    const selectedYear = parseInt(e.target.value, 10)

    // shader flag range position
    const rangePos = parseFloat(map(year, minYear, maxYear, 1, rangeStepsStartToEnd))
    setRangePosition(rangePos)

    // year
    setYear(selectedYear)
    // area, temperature
    setAreaTemp(selectedYear)
    // range
    setRangePosFn(selectedYear)
  }

  const [shaderRef, inView] = useInView()

  const markerStyles = {
    left: `${100 - ((maxYear - midYear) / (maxYear - minYear)) * 100}%`
  }

  const usaCan = (country.id === "USA" || country.id === "USA2" || country.id === "CAN" || country.id === "CAN2")

  return (
    <div className={classNames("dashboard container py-2", iFrame && "iFrame")}>
      <div className="row">
        <div ref={shaderRef} className={classNames("flag-wrapper d-flex flex-column justify-content-between", iFrame && "col-md" || "col-lg")}>
          <ShaderFlag
            id={country.id}
            rangePosition={rangePosition}
            classes={classNames("shader-flag show", inView && "show")}
            inView={inView}
            iFrame={iFrame}
          />
        </div>
        <div className={classNames("data-wrapper d-flex flex-column justify-content-between", iFrame && "col-md" || "col-lg")}>
          <div className="info">
            <h1 className="text-l">
              {country.title}
            </h1>
            <AnimatedNumber number={+year} decimalPlaces={0} decimalSections={false} classes="text-number" id="year-text" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className={classNames("flag-wrapper d-flex flex-column justify-content-between", iFrame && "col-md" || "col-lg")}>
          <div className="controls mt-2 mt-lg-0">
            <div className="timeline mt-lg-2">
              <div className="scrubber">
                <input
                  className="slider"
                  type="range"
                  min={minYear}
                  max={maxYear}
                  value={year}
                  step="1"
                  onChange={(e) => updateYear(e)}
                  onInput={(e) => updateYear(e)}
                />
                <span className="label" />
                <div className="marker d-none d-md-block" style={markerStyles} />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(usaCan && "lower", "data-wrapper d-flex flex-column justify-content-end", iFrame && "col-md" || "col-lg")}>
          <div className="stats">
            <div className="row px-0">
              {usaCan && (
                <div className="stats-details-wrap col-12 d-flex align-items-end mt-2 pb-2">
                  <span>&nbsp;</span>
                  <span className="text-s pl-md-2">
                    {country.id === "USA" && (
                      <PageTransitionLink to="/country/united-states-of-america-incl-kluane-wrangell">
                        with Kluane Wrangell glaciers
                      </PageTransitionLink>
                    )}
                    {country.id === "USA2" && (
                      <PageTransitionLink to="/country/united-states-of-america">
                        without Kluane Wrangell glaciers
                      </PageTransitionLink>
                    )}
                    {country.id === "CAN" && (
                      <PageTransitionLink to="/country/canada-incl-kluane-wrangell">
                        with Kluane Wrangell glaciers
                      </PageTransitionLink>
                    )}
                    {country.id === "CAN2" && (
                      <PageTransitionLink to="/country/canada">
                        without Kluane Wrangell glaciers
                      </PageTransitionLink>
                    )}
                  </span>
                </div>
              )}
              <div className="stats-details-wrap col-12 d-flex align-items-end mt-2 pb-2">
                <AnimatedNumber number={+area} decimalPlaces={1} classes="text-number" />
                <span className="text-s pl-md-2">
                  km
                  <sup>2</sup>
                  <br />
                  {`Area of ${diagram.hasUNESCOGlaciers ? "UNESCO" : ""} glaciers`}
                </span>
              </div>
              <div
                className={classNames("lower stats-details-wrap col-12 d-flex align-items-end mt-2 pb-2")}
              >
                <AnimatedNumber number={+temperature} decimalPlaces={1} decimalSections={false} classes="text-number" />
                <span className="text-s pl-md-2">
                  °C
                  <br />
                  Median annual temperature
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Dashboard.defaultProps = {
  iFrame: false
}

Dashboard.propTypes = {
  country: PropTypes.instanceOf(Object),
  iFrame: PropTypes.bool
}

export default Dashboard
