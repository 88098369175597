import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import AnimatedBarChart from "../AnimatedBarChart/AnimatedBarChart"

import "./Barchart.scss"

const Barchart = (props) => {
  const { diagram, headline, classes } = props
  const { data } = diagram;
  return (
    <>
      <h3 className="mb-2">{headline}</h3>
      <div className={classNames("barchart", classes)}>
        {data.length && (
          data.map((item) => {
            const value = (100 - (100 * parseFloat(item.end))).toFixed(0)
            const { label } = item

            return (
              <div className="bar d-flex mb-2" key={item.lat}>
                <AnimatedBarChart value={+value} classes="value" />
                <span className="label ">{`-${value}%`}</span>
                <span className="label ml-auto">{label}</span>
              </div>
            )
          })
        )}
      </div>
    </>
  )
}

Barchart.propTypes = {
  data: PropTypes.instanceOf(Object),
  headline: PropTypes.string,
  classes: PropTypes.string
}

Barchart.defaultProps = {
  data: {},
  headline: "",
  classes: ""
}

export default Barchart
